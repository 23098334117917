import React from 'react'
import { slide as Menu } from 'react-burger-menu'
import { Link } from 'gatsby'
import BurgerExpandableLink from './BurgerExpandableLink'
import ClientOnly from '../clientside/ClientOnly'
import Search from '../components/Search'
import './BurgerMenu.scss'

const BurgerMenu = ({isOpen, setIsOpen, children}) => {
  return (
    <div className='BurgerMenu'>
      <Menu 
        isOpen={isOpen}
        right
        width={ '100%' }
        onStateChange={(state) => {
          setIsOpen(state.isOpen);
        }}
       >
        <ClientOnly>
          <Search />
        </ClientOnly>
        <Link to="/">Home</Link>
        <BurgerExpandableLink to="/getting-to-know-your-body/" display="Getting to Know Your Body">
          <Link className="bm-item child" to="/getting-to-know-your-body/reproductive-systems">Reproductive Systems</Link>
          <Link className="bm-item child" to="/getting-to-know-your-body/menstruation">Menstruation</Link>
          <Link className="bm-item child" to="/getting-to-know-your-body/hygiene">Hygiene</Link>
        </BurgerExpandableLink>
        <BurgerExpandableLink to="/sex-and-relationships/" display="Sex and Relationships">
          <Link className="bm-item child" to="/sex-and-relationships/healthy-relationships">Healthy Relationships</Link>
          <Link className="bm-item child" to="/sex-and-relationships/sexuality-and-gender-101">Sexuality {"&"} Gender 101</Link>
        </BurgerExpandableLink>
        <BurgerExpandableLink to="/healthy-lifestyles/" display="Healthy Lifestyles">
          <Link className="bm-item child" to="/healthy-lifestyles/substance-use">Substance Use</Link>
          <Link className="bm-item child" to="/healthy-lifestyles/mental-health">Mental Health</Link>
        </BurgerExpandableLink>
        <BurgerExpandableLink to="/birth-control/" display="Birth Control">
          <Link className="bm-item child" to="/birth-control/birth-control-methods">Birth Control Methods</Link>
        </BurgerExpandableLink>
        <BurgerExpandableLink to="/transitioning-to-adult-care/" display="Transitioning to Adult Care">
          <Link className="bm-item child" to="/transitioning-to-adult-care/health-care-rights">What are your rights?</Link>
        </BurgerExpandableLink>
        <BurgerExpandableLink to="/preparing-for-your-visit" display="Preparing for your visit">
          <Link className="bm-item child" to="/preparing-for-your-visit/where-coulc-you-go">Where Could You Go for SRH Care? </Link>
          <Link className="bm-item child" to="/preparing-for-your-visit/how-to-pay-for-your-appointment">How to pay for your appointment</Link>
          <Link className="bm-item child" to="/preparing-for-your-visit/what-how">Brainstorming Ahead of Time</Link>
        </BurgerExpandableLink>
      </Menu>
    </div>
  )
}

export default BurgerMenu