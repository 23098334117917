import React, {useState} from 'react'
import BurgerMenu from './BurgerMenu'
import ClinicFinder from './ClinicFinder'
import './SectionHeader.scss'

const SectionHeader = () => {
  const [showFindClinic, setShowFindClinic] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  const modalClass = showFindClinic ? "visible": "";
  const searchClass = showSearch ? "visible": "";

  return (
    <div className='SectionHeader'>
      <div className={`modal ${modalClass}`}>
        <ClinicFinder modal={true} setCloseModal={() => setShowFindClinic(false)}/>
        <div className="overlay" onClick={() => setShowFindClinic(false)}/>
      </div>
      <div className="top-row">
        <div className="left">
          <img src="/assets/logo.svg" alt="logo"/>,
        </div>
        <div className="right">
          <div className="buttons">
            <button onClick={() => setShowSearch(true)}><img src="/assets/tmp_search.svg" alt="search" /></button>
            <button onClick={() => setShowFindClinic(true)}><img src="/assets/tmp_map_pin.svg" alt="search" /></button>
          </div>
          <BurgerMenu isOpen={showSearch} setIsOpen={setShowSearch}/>
        </div>
      </div>
    </div>
  )
}

export default SectionHeader