import React, {useState} from 'react'
import { Link } from 'gatsby'
import './BurgerExpandableLink.scss'

const BurgerExpandableLink = ({display, to, children}) => {
  const [contentOpen, setContentOpen] = useState(false);
  return (
    <div className='BurgerExpandableLink'>
      <div className="expandable">
        <Link className="bm-item" to={to}>{display}</Link>
        <button className={contentOpen ? 'down caret': 'caret'} onClick={() => setContentOpen(!contentOpen)}> </button>
      </div>
      {contentOpen &&
        <div className="children">
          {children}
        </div>
      }
    </div>
  )
}

export default BurgerExpandableLink